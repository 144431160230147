// import { i18n } from '@/plugins/i18n'
// import { apollo } from '@/plugins/apollo'

// graphql files
// import list from 'GraphQL/queries/feedback/list.gql'

export default {
  // async getList ({ rootState, commit }, payload = false) {
  //   if (payload) {
  //     await commit('setLoading', true, { root: true })
  //   }

  //   await apollo.query({
  //     query: list,
  //     variables: {
  //       apiToken: rootState.session.token.token
  //     }
  //   }).then(response => {
  //     const { status, result } = response.data.feedbackMessages

  //     switch (status) {
  //       case 'OK':
  //         commit('setList', result)
  //         break
  //       case 'ACCESSDENIED':
  //         commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
  //         break
  //       case 'BADREQUEST':
  //         commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
  //         break
  //       case 'UNPROCESSABLE':
  //         commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
  //         break
  //       case 'INTERNALERROR':
  //         commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
  //         break
  //     }
  //   }).catch(err => {
  //     console.error(err)
  //     commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
  //   }).finally(() => {
  //     if (payload) {
  //       commit('setLoading', false, { root: true })
  //     }
  //   })
  // },
}
