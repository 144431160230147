<template>
  <v-container key="client_details" fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
        <v-text-field
          v-model="object.name"
          :readonly="!isEditting"
          filled
          :background-color="!isEditting ? 'transparent' : ''"
          rounded
          hide-details
          :error="hasErrors('name')"
          :error-messages="getErrors('name')"
          :label="$t('clients.name')"
        />
      </v-col>

      <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
        <v-text-field
          v-model="object.code"
          :readonly="!isEditting"
          filled
          :background-color="!isEditting ? 'transparent' : ''"
          rounded
          hide-details
          :error="hasErrors('code')"
          :error-messages="getErrors('code')"
          :label="$t('clients.code')"
        />
      </v-col>

      <v-col cols="12" class="pa-2 d-flex flex-column">
        <v-autocomplete
          v-model="object.unitsIds"
          :readonly="!isEditting"
          :items="units"
          item-text="name"
          item-value="id"
          filled
          multiple
          :background-color="!isEditting ? 'transparent' : ''"
          rounded
          hide-details
          :error="hasErrors('unitsIds')"
          :error-messages="getErrors('unitsIds')"
          :label="$t('clients.unitsIds')"
        />
      </v-col>

      <v-col v-if="isEditting" cols="12" class="pa-2 d-flex align-center">
        <v-btn
          class="mx-1"
          depressed
          rounded
          text
          :loading="isLoading"
          :disabled="isLoading"
          color="red darken-2 white--text"
          @click="discardItem()"
        >
          {{ $t("actions.discard") }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mx-1"
          color="green darken-2 white--text"
          depressed
          rounded
          text
          :loading="isLoading"
          :disabled="isLoading"
          @click="saveItem()"
        >
          {{ $t("actions.save") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="showDates" no-gutters>
      <v-col cols="12" class="pa-2">
        <v-divider />
      </v-col>

      <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
        <v-text-field
          v-model="object.createdAt"
          readonly
          filled
          background-color="transparent"
          rounded
          hide-details
          :label="$t('clients.createdAt')"
        />
      </v-col>

      <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
        <v-text-field
          v-model="object.updatedAt"
          readonly
          filled
          background-color="transparent"
          rounded
          hide-details
          :label="$t('clients.updatedAt')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import edit from 'GraphQL/mutations/clients/edit.gql'
import { orm } from '@/mixins'

export default {
  mixins: [orm],
  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    data: { type: Object, default: () => {} },
    showDates: { type: Boolean, default: () => false },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      errors: {},
      isLoading: false,
      oldObject: {}
    }
  },

  computed: {
    ...mapState(['isDark']),
    ...mapGetters({
      defaultObject: 'clients/defaultObject',
      apiToken: 'users/token'
    }),
    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    isEditting: {
      get () {
        return !this.readonly
      },

      set (value) {
        this.$emit('set-readonly', value)
      }
    },

    units () {
      return this.$store.state.units.list
    }
  },

  watch: {
    isEditting (v) {
      if (v) {
        this.oldObject = Object.assign({}, this.object)
      }
    }
  },

  beforeDestroy () {
    this.resetErrors()
  },

  created () {
    this.resetErrors()
  },
  methods: {
    getIcon (type) {
      if (type === 'UNIT') {
        return 'mdi-car'
      }
      if (type === 'CLIENT') {
        return 'mdi-account'
      }
    },

    async saveItem () {
      this.isLoading = true
      const data = {
        id: this.object.id,
        name: this.object.name,
        code: this.object.code,
        unitsIds: this.object.unitsIds
      }
      await this.edit(data)
    },

    discardItem () {
      this.isEditting = false
      this.object.name = this.oldObject.name
      this.object.code = this.oldObject.code
      this.object.unitsIds = []
      this.object.unitsIds.push(...this.oldObject.unitsIds)
      this.oldObject = Object.assign({})
    },

    async evaluateResponse ({ status, errors, result }) {
      this.isLoading = false
      switch (status) {
        case 'OK':
          this.resetErrors()
          this.isEditting = false
          await this.$store.dispatch('clients/getList')
          await this.$store.dispatch('units/getList')
          this.$store.commit('toggleSnackbar', {
            color: 'green darken-2',
            message: this.$t('helpers.saved.successfully')
          })
          break
        case 'UNPROCESSABLE':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('helpers.saved.errors.unprocessable')
          })
          break
        case 'BADREQUEST':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('helpers.saved.errors.invalidFields')
          })
          break
      }
    },

    edit (data) {
      this.$apollo.mutate({
        mutation: edit,
        variables: {
          apiToken: this.apiToken,
          data: data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.editClient
        this.evaluateResponse({ status, errors, result })
      }).catch(err => {
        console.log('[Clients Dialog] Error handled', err)
        this.$emit('snackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    }
  }
}
</script>
