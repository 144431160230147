export default {
  defaultObject (_) {
    return {
      id: null,
      name: '',
      code: null,
      isDeleted: false,
      deletedAt: '',
      createdAt: '',
      updatedAt: '',
      unitsIds: []
    }
  }
}