<template>
  <v-dialog
    :value="dialog"
    :fullscreen="isMobile"
    :max-width="width.dialog"
    scrollable
    :persistent="isEditting"
    transition="dialog-transition"
    @click:outside="closeModal"
  >
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer />
        <v-btn icon text @click="discardItem">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="mb-4 mt-1" />

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
            <v-text-field
              v-model="object.name"
              :readonly="!isEditting"
              outlined
              hide-details
              :background-color="!isEditting ? 'transparent' : ''"
              rounded
              :label="$t('clients.name')"
              :error="hasErrors('name')"
              :error-messages="getErrors('name')"
            />
          </v-col>

          <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
            <v-text-field
              v-model="object.code"
              :readonly="!isEditting"
              outlined
              hide-details
              :background-color="!isEditting ? 'transparent' : ''"
              rounded
              :label="$t('clients.code')"
              :error="hasErrors('code')"
              :error-messages="getErrors('code')"
            />
          </v-col>

          <v-col cols="12" class="pa-2 d-flex flex-column">
            <v-autocomplete
              v-model="object.unitsIds"
              :readonly="!isEditting"
              outlined
              :items="units"
              item-text="name"
              item-value="id"
              hide-details
              multiple
              :background-color="!isEditting ? 'transparent' : ''"
              rounded
              :label="$t('clients.unitsIds')"
              :error="hasErrors('unitsIds')"
              :error-messages="getErrors('unitsIds')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="isEditting"
          class="mx-1"
          depressed
          rounded
          text
          :loading="isLoading"
          :disabled="isLoading"
          color="red darken-2 white--text"
          @click="discardItem"
        >
          <!-- <v-icon>mdi-close-circle</v-icon> -->
          {{ $t("actions.discard") }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mx-1"
          color="green darken-2 white--text"
          depressed
          rounded
          text
          :loading="isLoading"
          :disabled="isLoading"
          @click="saveItem"
        >
          <!-- <v-icon>mdi-check-circle</v-icon> -->
          {{ $t("actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import add from 'GraphQL/mutations/clients/add.gql'
import { orm } from '@/mixins'

export default {
  mixins: [orm],
  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    showDialog: {
      type: Boolean,
      default: () => false
    },
    isDark: {
      type: Boolean,
      default: () => false
    },
    isMobile: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      errors: {},
      isLoading: false,
      width: {
        full: window.innerWidth,
        dialog:
          window.innerWidth < 930
            ? window.innerWidth
            : window.innerWidth * 0.8333333333
      }
    }
  },

  computed: {
    ...mapState([]),
    ...mapGetters({
      defaultObject: 'clients/defaultObject',
      apiToken: 'users/token'
    }),

    isEditting: {
      get () {
        return !this.readonly
      },
      set (value) {
        this.$emit('set-readonly', !value)
      }
    },

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    color () {
      if (this.isDark) {
        return 'white'
      }

      return 'primary'
    },

    title () {
      return this.$i18n.t('clients.title.new', { name })
    },

    rawErrors () {
      const errs = {}

      for (const i in this.defaultObject) {
        errs[i] = ''
      }

      return errs
    },

    units () {
      return this.$store.state.units.list
    }
  },

  watch: {
    isLoading (newVal) {
      this.$emit('is-loading', newVal)
    }
  },

  beforeDestroy () {
    this.resetErrors()
  },

  created () {
    this.resetErrors()
    window.addEventListener('resize', () => {
      this.width = {
        full: window.innerWidth,
        dialog:
          window.innerWidth < 930
            ? window.innerWidth
            : window.innerWidth * 0.8333333333
      }
    })
  },

  methods: {
    closeModal () {
      if (!this.isEditting) {
        this.discardItem()
      }
    },

    async saveItem () {
      this.isLoading = true
      const data = {
        name: this.object.name,
        code: this.object.code,
        unitsIds: this.object.unitsIds
      }
      await this.add(data)
    },

    async evaluateResponse ({ status, errors, result }) {
      this.isLoading = false
      switch (status) {
        case 'OK':
          this.resetErrors()
          this.discardItem()
          await this.$store.dispatch('clients/getList')
          await this.$store.dispatch('units/getList')
          this.$store.commit('toggleSnackbar', {
            color: 'green darken-2',
            message: this.$t('helpers.saved.successfully')
          })
          break
        case 'UNPROCESSABLE':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('helpers.saved.errors.unprocessable')
          })
          break
        case 'BADREQUEST':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('helpers.saved.errors.invalidFields')
          })
          break
      }
    },

    add (data) {
      this.$apollo.mutate({
        mutation: add,
        variables: {
          apiToken: this.apiToken,
          data: data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.addClient
        this.evaluateResponse({ status, errors, result })
      }).catch(err => {
        console.log('[Clients Dialog] Error handled', err)
        this.$emit('snackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    discardItem () {
      this.dialog = false
      this.isEditting = false
      this.object = Object.assign({}, this.defaultObject)
      this.resetErrors()
    }
  }
}
</script>
