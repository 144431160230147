<template>
  <v-app-bar color="transparent" class="elevation-3" :height="72.5">
    <!-- <v-app-bar-nav-icon v-if="isMobile" @click="$store.commit('setDrawer', true)" /> -->

    <v-toolbar-title class="d-flex align-center">
      <img :src="logo" :height="hasWhitelabel ? 40 : 50" :class="{'mr-3': hasWhitelabel}">
      {{ appName }}
    </v-toolbar-title>

    <v-spacer />

    <v-btn
      v-for="(el, i) in sidebarLinks"
      :key="i"
      color="primary"
      :text="$route.path !== el.path"
      depressed
      :to="el.path"
    >
      {{ $t(`pages.${el.name}`) }}
    </v-btn>

    <!-- <v-btn :color="$route.path === '/Home' ? 'primary' : 'transparent'" depressed to="/Home">
      {{ $t('pages.ttc.home') }}
    </v-btn>

    <v-btn v-if="hasGeofences" :color="$route.path === '/Iris' ? 'primary' : 'transparent'" depressed to="/Iris">
      {{ $t('pages.ttc.geofences') }}
    </v-btn> -->

    <!-- <v-tooltip v-if="hasLoginAs" bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" @click="rollback">
          <v-icon>mdi-{{ $store.state.loginAs.type === 'goldenm.User' ? 'account-reactivate-outline' : 'backup-restore' }}</v-icon>
        </v-btn>
      </template>

      <span>{{ $t(`loginAs.${$store.state.loginAs.type === 'goldenm.User' ? 'admin' : 'rollback' }`) }}</span>
    </v-tooltip> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn class="mx-2" icon v-on="on" @click="toggleDark">
          <v-icon>mdi-theme-light-dark</v-icon>
        </v-btn>
      </template>

      <span>{{ $t(`actions.darkMode.${isDark}`) }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mx-2" icon v-on="on">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>

      <span>{{ $t(`actions.close`) }}</span>
    </v-tooltip>

    <!-- <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-avatar
          v-if="hasAvatar"
          class="elevation-1"
          size="40"
          style="cursor: pointer;"
          v-on="on"
        >
          <v-img :src="avatar + `?random=${randomNumber}`" contain />
        </v-avatar>

        <v-avatar
          v-else
          class="elevation-1"
          :color="`${isDark ? 'secondary' : 'primary'} white--text`"
          size="40"
          style="cursor: pointer;"
          v-on="on"
        >
          {{ avatar }}
        </v-avatar>
      </template>

      <v-list dense>
        <v-list-item to="/Settings/PodIOTSuites">{{ $t('headers.pod.configuration') }}</v-list-item>
        <v-list-item to="/Settings/Shopify">{{ $t('headers.shopify.configuration') }}</v-list-item>
        <v-list-item @click="$store.dispatch('users/logout')">{{ $t('users.signOut') }}</v-list-item>
      </v-list>
    </v-menu> -->
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      loginAs: false,
      newAccount: '',
      randomNumber: 0
    }
  },

  computed: {
    ...mapState(['users', 'isDark', 'whitelabel']),
    ...mapGetters(['isMobile', 'version']),

    ...mapGetters({
      avatar: 'users/avatar',
      hasAvatar: 'users/hasAvatar',
      fullName: 'users/fullName'
    }),

    ...mapGetters(['isMobile']),

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    },

    logo () {
      if (this.$store.state.isWhitelabelLoading) {
        return ''
      }

      if (this.whitelabel.logo === undefined) {
        return this.isDark ? this.$assets.faviconWhite : this.$assets.favicon
      }

      return this.whitelabel.logo
    },

    appName () {
      if (this.$store.state.isWhitelabelLoading) {
        return ''
      }

      if (this.whitelabel.name === undefined) {
        return this.$i18n.t('app.site')
      }

      return this.whitelabel.name
    },

    user () {
      return this.users.entity
    },

    hasGeofences () {
      if (this.user.__typename === 'Customer') {
        return true
      }

      if (this.user.__typename === 'Operator' && this.user.permission !== undefined) {
        return this.user.permission.geofences >= 1
      }

      return false
    },

    sidebarLinks () {
      return [
        // {
        //   path: '/Home',
        //   name: 'home'
        // }
      ]
    }
  },

  watch: {
    user (newVal, oldVal) {
      this.randomNumber = parseInt(Math.random() * 1000000)
    }
  },

  methods: {
    ...mapMutations(['toggleDark'])
  }
}
</script>