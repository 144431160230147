<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 d-flex align-center">
        <h3 class="mr-1">
          {{ $t("directories.title") }}
        </h3>
        <v-divider vertical class="mx-2" />

        <v-btn
          color="primary"
          depressed
          class="mx-1"
          rounded
          :outlined="filter !== 'ALL'"
          @click="filter = 'ALL'"
        >
          {{ $t("directories.filters.all") }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          class="mx-1"
          rounded
          :outlined="filter !== 'CUSTOMERS'"
          @click="filter = 'CUSTOMERS'"
        >
          {{ $t("directories.filters.customers") }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          class="mx-1"
          rounded
          :outlined="filter !== 'UNITS'"
          @click="filter = 'UNITS'"
        >
          {{ $t("directories.filters.units") }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          class="mx-1"
          rounded
          :outlined="filter !== 'DISCHARGED'"
          disabled
          @click="filter = 'DISCHARGED'"
        >
          {{ $t("directories.filters.discharged") }}
        </v-btn>

        <v-spacer />

        <v-text-field
          v-model="search"
          rounded
          class="mx-1"
          :label="$t('actions.search')"
          dense
          prepend-inner-icon="mdi-magnify"
          filled
          hide-details
        />

        <v-btn
          color="primary"
          fab
          small
          class="mx-1"
          @click="addClient()"
        >
          <v-icon color="white">mdi-account-plus</v-icon>
        </v-btn>
        <v-btn color="primary" fab small class="ml-1" disabled>
          <v-icon color="white">mdi-car-2-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col
        cols="12"
        :md="fullView ? '4' : '7'"
        :lg="fullView ? '3' : '8'"
        class="d-flex flex-column"
      >
        <v-row v-if="items.length > 0" no-gutters>
          <template v-if="!fullView">
            <v-col cols="12" class="pa-2">
              <v-data-table
                class="transparent"
                item-key="listingId"
                :headers="headers"
                :search="search"
                :page.sync="page"
                :custom-filter="directoriesTableFilter"
                :items="filteredItems"
                :items-per-page.sync="itemsPerPage"
                hide-default-footer
              >
                <template v-slot:item.type="{ item }">
                  <v-icon class="mr-1">{{ getIcon(item.type) }}</v-icon>
                  {{ $t(`directories.type.${item.type}`) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn icon small @click="getDetails(item)">
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </template>

          <template v-else>
            <v-col cols="12" class="pa-2">
              <v-data-iterator
                :items="filteredItems"
                item-key="listingId"
                hide-default-footer
                :page.sync="page"
                :search="search"
                :custom-filter="directoriesFilter"
                :items-per-page.sync="itemsPerPage"
                :no-results-text="$t('tables.noResultsText')"
                no-data-text=""
              >
                <template #default="{ items }">
                  <v-list dense color="transparent">
                    <v-subheader>
                      <h4>
                        {{ $t("directories.list") }}
                      </h4>
                    </v-subheader>
                    <template v-for="(item, i) in items">
                      <v-divider :key="`d-${i}`" />
                      <v-list-item :key="item.listingId" @click="getDetails(item)">
                        <v-list-item-avatar>
                          <v-icon>
                            {{ getIcon(item.type) }}
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Lorem ipsum
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <!-- <v-virtual-scroll
                  :bench="24"
                  class="better-scrollbar"
                  :items="items"
                  height="calc(100vh - 288px)"
                  item-height="72"
                >
                  <template #default="{ item }">
                    <v-divider />
                    <v-list-item :key="item.id">
                      <v-list-item-icon>
                        <v-avatar size="40" color="transparent">
                          <v-icon color="primary">
                            {{ getIcon(item.type) }}
                          </v-icon>
                        </v-avatar>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Lorem ipsum
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll> -->
                  </v-list>
                </template>
              </v-data-iterator>
            </v-col>
          </template>

          <v-col
            cols="12"
            class="text-center d-flex flex-column justify-end pa-2"
          >
            <div class="">
              <v-pagination v-model="page" total-visible="7" :length="pages" />
            </div>
          </v-col>
        </v-row>

        <v-row v-else no-gutters>
          <v-col cols="12" class="pa-2 py-6 d-flex flex-column align-center">
            <v-icon style="font-size: 5rem">mdi-alert-rhombus</v-icon>
            <h1 class="mt-3 text-center">
              {{ $t("tables.noResultsText") }}
            </h1>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        :md="fullView ? '8' : '5'"
        :lg="fullView ? '9' : '4'"
        class="pa-2 d-flex flex-column"
      >
        <directories-details
          v-model="object"
          :readonly="!isEditting"
          :full-view="fullView"
          @toggle-dialog="(state) => {}"
          @set-readonly="(state) => (isEditting = state)"
          @set-full-view="(state) => (fullView = state)"
        />
      </v-col>
    </v-row>

    <clients-dialog
      v-model="clientsObject"
      :show-dialog="clientsDialog"
      :readonly="!clientsIsEditting"
      @toggle-dialog="(state) => (clientsDialog = state)"
      @set-readonly="(state) => (clientsIsEditting = state)"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { filters } from '@/mixins'
import Details from './Details.vue'
import { Dialog } from '@/components/clients'

export default {
  components: {
    'directories-details': Details,
    'clients-dialog': Dialog
  },
  mixins: [filters],

  props: {
    items: { type: Array, default: () => [] }
  },

  data () {
    return {
      object: {},
      isEditting: false,
      search: '',
      page: 1,
      itemsPerPage: 12,
      filter: 'ALL',
      fullView: false,

      clientsDialog: false,
      clientsObject: {},
      clientsIsEditting: false
    }
  },

  computed: {
    ...mapState(['session', 'width', 'isDark']),
    ...mapGetters(['color', 'isMobile']),
    ...mapGetters({
      defaultObject: 'directories/defaultObject',
      clientsDefaultObject: 'clients/defaultObject'
    }),

    pages () {
      return Math.ceil(this.filteredItems.length / Math.abs(this.itemsPerPage))
    },

    headers () {
      return [
        { text: this.$i18n.t('directories.type.title'), value: 'type' },
        { text: this.$i18n.t('directories.name'), value: 'name' },
        { text: this.$i18n.t('directories.updated'), value: 'updatedAt' },
        { text: this.$i18n.t('directories.created'), value: 'createdAt' },
        { text: '', value: 'actions', sortable: false }
      ]
    },

    filteredItems () {
      if (this.filter === 'CUSTOMERS') {
        return this.items.filter((el) => el.type === 'CLIENT')
      }
      if (this.filter === 'UNITS') {
        return this.items.filter((el) => el.type === 'UNIT')
      }
      if (this.filter === 'DISCHARGED') {
        return this.items.filter((el) => el.discharged)
      }
      return this.items
    }
  },

  watch: {
    isLoading (newVal) {
      this.$emit('is-loading', newVal)
    },

    page (v) {
      window.scrollTo({ top: 0 })
    }
  },

  mounted () {},

  created () {
    this.object = Object.assign({}, this.defaultObject)
  },

  methods: {
    getIcon (type) {
      if (type === 'UNIT') {
        return 'mdi-car'
      }
      if (type === 'CLIENT') {
        return 'mdi-account'
      }
    },

    getDetails (item) {
      this.isEditting = false
      this.object = Object.assign({}, item)
    },

    addClient () {
      this.clientsIsEditting = true
      this.clientsDialog = true
      this.clientsObject = Object.assign({}, this.clientsDefaultObject)
    }
  }
}
</script>
