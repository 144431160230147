<template>
  <v-card width="100%" min-height="calc(100vh - 216px)" outlined>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" class="pa-2 d-flex align-center">
          <h4 v-if="!fullView">
            {{ $t("directories.preview") }}
          </h4>

          <div v-else class="d-flex align-center">
            <v-avatar
              size="50"
              class="mr-2"
              color="transparent"
              :style="{
                border: 'solid 1px #9E9E9E !important',
              }"
            >
              <v-icon large>{{ getIcon(object.type) }}</v-icon>
            </v-avatar>
            <h2>
              {{ object.name }}
            </h2>
          </div>

          <v-spacer />
          <div v-if="object.id" class="d-flex">
            <v-btn icon color="blue" @click="displayFullView()">
              <v-icon>{{ showFullView ? 'mdi-arrow-left-circle' : 'mdi-eye' }}</v-icon>
            </v-btn>
            <template v-if="object.type === 'CLIENT'">
              <v-btn v-if="!isEditting" icon color="amber" @click="editItem()">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <v-btn icon>
              <v-icon>mdi-message</v-icon>
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-if="object.type === 'CLIENT'">
                  <v-list-item @click="deleteItem()">
                    <v-list-item-title>
                      {{ $t("actions.delete") }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t("actions.unsubscribe") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="!object.id" no-gutters>
        <v-col cols="12" class="pa-2 text-center">
          <span class="grey--text">
            {{ $t("tables.noSelectedText") }}
          </span>
        </v-col>
      </v-row>

      <template v-else>
        <template v-if="!fullView">
          <v-row no-gutters>
            <v-col cols="12" class="pa-2 d-flex align-center justify-center">
              <div class="d-flex flex-column align-center">
                <v-avatar
                  size="70"
                  color="transparent"
                  :style="{
                    border: 'solid 1px #9E9E9E !important',
                  }"
                >
                  <v-icon x-large>{{ getIcon(object.type) }}</v-icon>
                </v-avatar>
                <h2>
                  {{ object.name }}
                </h2>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" class="pa-2">
              <v-tabs v-model="tab" fixed-tabs>
                <v-tabs-slider color="primary" />

                <v-tab>
                  {{ $t("directories.details") }}
                </v-tab>
                <v-tab>
                  {{ $t("directories.log") }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <template v-if="object.type === 'CLIENT'">
                    <client-details-tab
                      v-model="object"
                      :readonly="!isEditting"
                      @set-readonly="(state) => (isEditting = state)"
                    />
                  </template>
                  <template v-else>
                    <unit-details-tab
                      v-model="object"
                      :readonly="!isEditting"
                      @set-readonly="(state) => (isEditting = state)"
                    />
                  </template>
                </v-tab-item>

                <v-tab-item>
                  <log-tab
                    v-model="object"
                    :readonly="!isEditting"
                    :search="search"
                    @set-readonly="(state) => (isEditting = state)"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-row no-gutters>
            <v-col cols="12" md="6" class="d-flex flex-column pa-2">
              <v-sheet
                height="56px"
                width="100%"
                class="pa-2 d-flex align-center"
              >
                <h3 class="primary--text">
                  {{ $t("directories.details") }}
                </h3>
              </v-sheet>
              <template v-if="object.type === 'CLIENT'">
                <client-details-tab
                  v-model="object"
                  :readonly="!isEditting"
                  show-dates
                  @set-readonly="(state) => (isEditting = state)"
                />
              </template>
              <template v-else>
                <unit-details-tab
                  v-model="object"
                  :readonly="!isEditting"
                  show-dates
                  @set-readonly="(state) => (isEditting = state)"
                />
              </template>
            </v-col>

            <v-col cols="12" md="6" class="d-flex flex-column pa-2">
              <v-sheet
                height="56px"
                width="100%"
                cols="12"
                class="pa-2 d-flex align-center"
              >
                <h3 class="primary--text">
                  {{ $t("directories.log") }}
                </h3>

                <v-spacer />

                <v-text-field
                  v-model="search"
                  rounded
                  class="ml-2"
                  :label="$t('actions.search')"
                  dense
                  prepend-inner-icon="mdi-magnify"
                  outlined
                  hide-details
                />
              </v-sheet>
              <log-tab
                v-model="object"
                :readonly="!isEditting"
                :search="search"
                @set-readonly="(state) => (isEditting = state)"
              />
            </v-col>
          </v-row>
        </template>
      </template>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import UnitDetails from './tabs/UnitDetails.vue'
import ClientDetails from './tabs/ClientDetails.vue'
import Log from './tabs/Log.vue'
import deleteClient from 'GraphQL/mutations/clients/delete.gql'

export default {
  components: {
    'client-details-tab': ClientDetails,
    'unit-details-tab': UnitDetails,
    'log-tab': Log
  },
  mixins: [],
  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    showDialog: { type: Boolean, default: () => false },
    fullView: { type: Boolean, default: () => false },
    data: { type: Object, default: () => {} },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      errors: {},
      tab: 0,
      search: '',
      isLoading: false
    }
  },

  computed: {
    ...mapState(['isDark']),
    ...mapGetters({
      directoriesDefaultObject: 'directories/defaultObject',
      apiToken: 'users/token'
    }),

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    showFullView: {
      get () {
        return this.fullView
      },

      set (value) {
        this.$emit('set-full-view', value)
      }
    },

    isEditting: {
      get () {
        return !this.readonly
      },

      set (value) {
        this.$emit('set-readonly', value)
      }
    }
  },

  watch: {},

  created () {},

  methods: {
    getIcon (type) {
      if (type === 'UNIT') {
        return 'mdi-car'
      }
      if (type === 'CLIENT') {
        return 'mdi-account'
      }
    },

    displayFullView () {
      this.showFullView = !this.showFullView
    },

    editItem () {
      this.isEditting = !this.isEditting
      this.tab = 0
    },

    async deleteItem () {
      this.isLoading = true
      this.$store.commit('setLoading', true)
      const id = this.object.id
      if (this.object.type === 'CLIENT') {
        await this.deleteClient(id)
      }
    },

    deleteClient (id) {
      this.$apollo.mutate({
        mutation: deleteClient,
        variables: {
          apiToken: this.apiToken,
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors } = response.data.deleteClient
        this.evaluateResponse({ status, errors })
      }).catch(err => {
        console.log('[Clients Dialog] Error handled', err)
        this.$emit('snackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    async evaluateResponse ({ status, errors, result }) {
      this.isLoading = false
      this.$store.commit('setLoading', false)
      switch (status) {
        case 'OK':
          this.isEditting = false
          if (this.object.type === 'CLIENT') {
            await this.$store.dispatch('clients/getList')
            await this.$store.dispatch('units/getList')
            this.object = Object.assign({}, this.directoriesDefaultObject)
            this.$set(this.object, 'id', null)
            this.$set(this.object, 'type', null)
          }
          this.$store.commit('toggleSnackbar', {
            color: 'green darken-2',
            message: this.$t('helpers.delete.success')
          })
          break
        case 'UNPROCESSABLE':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('helpers.saved.errors.unprocessable')
          })
          break
        case 'BADREQUEST':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('helpers.saved.errors.invalidFields')
          })
          break
      }
    }
  }
}
</script>
