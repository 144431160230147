<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12" class="pa-2">
        <v-data-iterator
          :items="items"
          item-key="id"
          hide-default-footer
          :search="search"
          :items-per-page.sync="itemsPerPage"
          :no-results-text="$t('tables.noResultsText')"
          no-data-text=""
        >
          <template #default="{ items }">
            <v-list dense color="transparent">
              <v-virtual-scroll
                :bench="24"
                class="better-scrollbar"
                :items="items"
                height="calc(100vh - 216px)"
                item-height="57"
              >
                <template #default="{ item }">
                  <v-divider />
                  <v-list-item :key="item.id">
                    <v-list-item-avatar>
                      <v-icon>
                        {{ getIcon(item.action) }}
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ getTitle(item) }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip v-if="getSubtitle(item) !== ''" small outlined>
                          {{ getSubtitle(item) }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-title>
                        {{ formatDate(item.createdAt) }}
                      </v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import dayjs from 'dayjs'

export default {
  mixins: [],
  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    data: { type: Object, default: () => {} },
    search: { type: String, default: () => '' },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      errors: {},
      itemsPerPage: -1
    }
  },

  computed: {
    ...mapState(['isDark']),
    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    isEditting: {
      get () {
        return !this.readonly
      },

      set (value) {
        this.$emit('set-readonly', value)
      }
    },

    items () {
      if (this.object.log) {
        return this.object.log
      }
      return []
    }
  },

  watch: {},

  created () {},

  methods: {
    getIcon (action) {
      if (action === 'ADDED') {
        return 'mdi-plus-circle'
      }
      if (action === 'EDITED') {
        return 'mdi-pencil-circle'
      }
      if (action === 'ASSOCIATED') {
        return 'mdi-link'
      }
      if (action === 'IMPORTED') {
        return 'mdi-import'
      }
      if (action === 'UNLINKED') {
        return 'mdi-link-off'
      }
      if (action === 'NEWEVENT') {
        return 'mdi-new-box'
      }
    },

    formatDate (date) {
      return dayjs(date).format('DD/MM/YYYY HH:mm')
    },

    getSubtitle (item) {
      if (this.object.type === 'CLIENT' && item.unitId) {
        return `${item.unit.name}`
      }
      if (this.object.type === 'UNIT' && item.clientId) {
        return `${item.client.name}`
      }
      return ''
    },

    getTitle (item) {
      if (this.object.type === 'CLIENT') {
        if (item.action === 'ASSOCIATED' && item.unitId) {
          return this.$i18n.t(`clients.logs.${item.action}`, { unit: item.unit.name })
        }
        return this.$i18n.t(`clients.logs.${item.action}`)
      }

      if (this.object.type === 'UNIT') {
        if (item.action === 'ASSOCIATED' && item.clientId) {
          return this.$i18n.t(`clients.logs.${item.action}`, { client: item.client.name })
        }
        return this.$i18n.t(`units.logs.${item.action}`)
      }
      return ''
    }
  }
}
</script>
