export default {
  defaultObject (_) {
    return {
      id: null,
      name: '',
      phoneNumber1: '',
      phoneNumber2: '',
      phoneNumber3: '',
      keyword: '',
      isDeleted: false,
      deletedAt: '',
      createdAt: '',
      updatedAt: '',
      comments: '',
      unitId: null
    }
  }
}