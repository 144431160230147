<template>
  <v-container key="unit_details" fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
        <v-text-field
          v-model="object.name"
          readonly
          filled
          background-color="transparent"
          rounded
          hide-details
          :label="$t('units.name')"
        />
      </v-col>

      <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
        <v-text-field
          v-model="object.imei"
          readonly
          filled
          background-color="transparent"
          rounded
          hide-details
          :label="$t('units.imei')"
        />
      </v-col>

      <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
        <v-text-field
          v-model="object.wialonName"
          readonly
          filled
          background-color="transparent"
          rounded
          hide-details
          :label="$t('units.wialonName')"
        />
      </v-col>

      <v-col cols="12" md="6" class="pa-2 d-flex flex-column">
        <v-text-field
          v-model="object.wialonImei"
          readonly
          filled
          background-color="transparent"
          rounded
          hide-details
          :label="$t('units.wialonImei')"
        />
      </v-col>

      <v-col v-if="object.customFields.length > 0" cols="12">
        <h4 class="ml-2">{{ $t("units.customFields.title") }}:</h4>
        <v-sheet width="100%">
          <v-card elevation="0" class="ma-2 pa-2">
            <v-row no-gutters>
              <v-col
                v-if="object.customFields.filter(el => el.key !== null).length === 0"
                cols="12"
                class="d-flex align-center justify-center pa-2"
              >
                <span>
                  {{ $t('helpers.noData') }}
                </span>
              </v-col>
              <template v-else>
                <v-col
                  v-for="(item, i) in object.customFields.filter(el => el.key !== null)"
                  :key="i"
                  cols="12"
                  md="6"
                  lg="4"
                  xl="3"
                  class="pa-2"
                >
                  <small class="grey--text font-weight-bold mr-1">
                    {{ item.key }}:
                  </small>
                  <v-chip small label outlined>
                    {{ item.value ? item.value : 'N/A' }}
                  </v-chip>
                </v-col>
              </template>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>

      <v-col cols="12" class="pa-2" />

      <v-col v-if="showDates && object.contactPersons.length > 0" cols="12">
        <div class="d-flex align-center justify-space-between mx-2">
          <h4>{{ $t("contactPersons.title.list") }}:</h4>
          <v-btn icon color="success" @click="addContactPerson()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-sheet
          width="100%"
          class="better-scrollbar"
          style="overflow-y: auto"
          color="transparent"
        >
          <v-row no-gutters>
            <template v-for="(item, i) in object.contactPersons">
              <v-col :key="i" cols="12" lg="6" xl="4" class="d-flex flex-column pa-2">
                <v-card width="100%" height="100%" outlined class="pa-2">
                  <v-row no-gutters>
                    <v-col cols="12" class="pb-2 d-flex align-center">
                      <v-chip outlined class="mr-2">
                        <small class="mr-1">{{ $t("directories.created") }}:</small>
                        <span>{{ item.createdAt }}</span>
                      </v-chip>
                      <v-chip outlined>
                        <small class="mr-1">{{ $t("directories.updated") }}:</small>
                        <span>{{ item.updatedAt }}</span>
                      </v-chip>

                      <v-spacer />
                      <v-btn color="amber" icon small @click="editContactPerson(item)">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon small>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="deleteContactPerson(item)">
                            <v-list-item-title>
                              {{ $t("actions.delete") }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      class="pa-2 d-flex flex-column"
                    >
                      <small class="grey--text font-weight-bold">{{
                        $t("contactPersons.name")
                      }}</small>
                      <span>{{ item.name ? item.name : "N/A" }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      class="pa-2 d-flex flex-column"
                    >
                      <small class="grey--text font-weight-bold">{{
                        $t("contactPersons.keyword")
                      }}</small>
                      <span>{{ item.keyword ? item.keyword : "N/A" }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      class="pa-2 d-flex flex-column"
                    >
                      <small class="grey--text font-weight-bold">{{
                        $t("contactPersons.phoneNumber1")
                      }}</small>
                      <span>{{
                        item.phoneNumber1 ? item.phoneNumber1 : "N/A"
                      }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      class="pa-2 d-flex flex-column"
                    >
                      <small class="grey--text font-weight-bold">{{
                        $t("contactPersons.phoneNumber2")
                      }}</small>
                      <span>{{
                        item.phoneNumber2 ? item.phoneNumber2 : "N/A"
                      }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      class="pa-2 d-flex flex-column"
                    >
                      <small class="grey--text font-weight-bold">{{
                        $t("contactPersons.phoneNumber3")
                      }}</small>
                      <span>{{
                        item.phoneNumber3 ? item.phoneNumber3 : "N/A"
                      }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      class="pa-2 d-flex flex-column"
                    >
                      <small class="grey--text font-weight-bold">{{
                        $t("contactPersons.comments")
                      }}</small>
                      <span>{{
                        item.comments ? item.comments : "N/A"
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <contact-persons-dialog
      v-model="contactPersonsObject"
      :show-dialog="contactPersonsDialog"
      :readonly="!contactPersonsIsEditting"
      @toggle-dialog="(state) => (contactPersonsDialog = state)"
      @set-readonly="(state) => (contactPersonsIsEditting = state)"
      @add-contact-person="pushContactPerson"
      @set-contact-person="setContactPerson"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Dialog } from '@/components/contactPersons'
import deleteContactPerson from 'GraphQL/mutations/contactPersons/delete.gql'

export default {
  components: {
    'contact-persons-dialog': Dialog
  },
  mixins: [],
  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    data: { type: Object, default: () => {} },
    showDates: { type: Boolean, default: () => false },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      errors: {},
      contactPersonsIsEditting: false,
      contactPersonsObject: {},
      contactPersonsDialog: false
    }
  },

  computed: {
    ...mapState(['isDark']),
    ...mapGetters({
      contactPersonsDefaultObject: 'contactPersons/defaultObject',
      apiToken: 'users/token'
    }),

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    isEditting: {
      get () {
        return !this.readonly
      },

      set (value) {
        this.$emit('set-readonly', value)
      }
    }
  },

  watch: {},

  created () {},

  methods: {
    getIcon (type) {
      if (type === 'UNIT') {
        return 'mdi-car'
      }
      if (type === 'CLIENT') {
        return 'mdi-account'
      }
    },

    addContactPerson () {
      this.contactPersonsObject = Object.assign({}, this.contactPersonsDefaultObject)
      this.contactPersonsObject.unitId = this.object.id
      this.contactPersonsIsEditting = true
      this.contactPersonsDialog = true
    },

    editContactPerson (item) {
      this.contactPersonsObject = Object.assign({}, item)
      this.contactPersonsObject.unitId = this.object.id
      this.contactPersonsIsEditting = true
      this.contactPersonsDialog = true
    },

    pushContactPerson (item) {
      this.object.contactPersons.push(item)
    },

    spliceContactPerson (id) {
      const array = [...this.object.contactPersons]
      this.object.contactPersons = array.filter(el => el.id !== id)
    },

    setContactPerson (item) {
      const array = [...this.object.contactPersons]
      const index = this.object.contactPersons.findIndex(el => el.id === item.id)
      array[index] = item
      this.object.contactPersons = []
      this.object.contactPersons.push(...array)
    },

    async deleteContactPerson (item) {
      this.isLoading = true
      this.$store.commit('setLoading', true)
      const id = item.id
      await this.delete(id)
    },

    delete (id) {
      this.$apollo.mutate({
        mutation: deleteContactPerson,
        variables: {
          apiToken: this.apiToken,
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors } = response.data.deleteContactPerson
        this.evaluateResponse({ status, errors, id })
      }).catch(err => {
        console.log('[Clients Dialog] Error handled', err)
        this.$emit('snackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    async evaluateResponse ({ status, errors, id }) {
      this.isLoading = false
      this.$store.commit('setLoading', false)
      switch (status) {
        case 'OK':
          this.isEditting = false
          this.spliceContactPerson(id)
          await this.$store.dispatch('clients/getList')
          await this.$store.dispatch('units/getList')
          this.$store.commit('toggleSnackbar', {
            color: 'green darken-2',
            message: this.$t('helpers.delete.success')
          })
          break
        case 'UNPROCESSABLE':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('helpers.saved.errors.unprocessable')
          })
          break
        case 'BADREQUEST':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('helpers.saved.errors.invalidFields')
          })
          break
      }
    }
  }
}
</script>
