// Main imports
import Vue from 'vue'
import VueApollo from 'vue-apollo'

// Apollo client dependencies
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

// Create component
Vue.use(VueApollo)

const httpLink = new HttpLink({
  uri: (process.env.NODE_ENV === 'development' ? 'http://localhost:8000/graphql' : 'https://api.directorio.monitorcenter.gpsttc.mx/graphql')
})

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache'
  },
  mutate: {
    fetchPolicy: 'no-cache'
  }
}

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions
})

export default new VueApollo({
  defaultClient: apolloClient,
  defaultOptions
})

export { apolloClient as apollo }
