<template>
  <directory-listing
    :items="items"
    @snackbar="(values) => $store.commit('toggleSnackbar', values)"
    @isLoading="(value) => $store.commit('setLoading', value)"
  />
</template>

<script>
import { mapState } from 'vuex'
import { Listing } from '@/components/directory'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
    'directory-listing': Listing
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(['isDark', 'units', 'clients']),

    // items () {
    //   return Array(20)
    //     .fill({})
    //     .map((_, i) => {
    //       return {
    //         id: i + 1,
    //         name: `Lorem ${i + 1}`,
    //         type: this.generateType(this.generateNumber(1, 2)),
    //         discharged: this.generateNumber(0, 1) === 1,
    //         updated: dayjs().format('DD/MM/YYYY HH:mm'),
    //         created: dayjs().format('DD/MM/YYYY HH:mm'),

    //         imei: 'IMEI123',
    //         sd: 'SD123',
    //         customerName: 'Lorem ipsum',
    //         assignmentDate: dayjs().format('DD/MM/YYYY HH:mm'),
    //         contact: 'Lorem ipsum',
    //         phone: '+58000000',

    //         log: Array(10)
    //           .fill({})
    //           .map((_, j) => {
    //             return {
    //               id: j + 1,
    //               type: this.generateNumber(1, 3),
    //               title: 'Log title',
    //               description:
    //                 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
    //               date: dayjs().format('DD/MM/YYYY HH:mm')
    //             }
    //           })
    //       }
    //     })
    // }

    items () {
      return [
        ...this.units.list.map((el) => {
          return {
            listingId: `UNIT-${el.id}`,
            id: el.id,
            type: 'UNIT',
            name: el.name,
            imei: el.imei,
            wialonName: el.wialonName,
            wialonImei: el.wialonImei,
            createdAt: '------------',
            updatedAt: '------------',
            customFields: el.customFields,
            contactPersons: el.contactPersons.map(cp => {
              return {
                id: cp.id,
                name: cp.name,
                phoneNumber1: cp.phoneNumber1,
                phoneNumber2: cp.phoneNumber2,
                phoneNumber3: cp.phoneNumber3,
                keyword: cp.keyword,
                comments: cp.comments,
                deletedAt: dayjs(cp.deletedAt).format('DD/MM/YYYY HH:mm'),
                createdAt: dayjs(cp.createdAt).format('DD/MM/YYYY HH:mm'),
                updatedAt: dayjs(cp.updatedAt).format('DD/MM/YYYY HH:mm')

              }
            }),
            log: el.logs
          }
        }),

        ...this.clients.list.map((el) => {
          return {
            listingId: `CLIENT-${el.id}`,
            id: el.id,
            type: 'CLIENT',
            name: el.name,
            code: el.code,
            isDeleted: el.isDeleted,
            deletedAt: dayjs(el.deletedAt).format('DD/MM/YYYY HH:mm'),
            createdAt: dayjs(el.createdAt).format('DD/MM/YYYY HH:mm'),
            updatedAt: dayjs(el.updatedAt).format('DD/MM/YYYY HH:mm'),
            unitsIds: el.unitsIds,
            log: el.logs
          }
        })
      ]
    }
  },

  watch: {},

  mounted () {},

  methods: {
    generateNumber (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },

    generateType (number) {
      if (number === 1) {
        return 'ASSET'
      }
      if (number === 2) {
        return 'USER'
      }
    }
  }
}
</script>
