import LoginLayout from '@/layouts/Login.vue'
import AppLayout from '@/layouts/Main.vue'

import NotFound from '@/views/NotFound.vue'
// import Login from '@/views/Login.vue'
// import Recovery from '@/views/Recovery.vue'
import Home from '@/views/Home.vue'

export default [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/',
    component: LoginLayout,
    children: [
      // {
      //   path: 'Login',
      //   name: 'login',
      //   components: { default: Login }
      // },
      // {
      //   path: 'Recovery',
      //   name: 'recovery',
      //   components: { default: Recovery }
      // }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: 'Home',
        name: 'home', /* IF YOU USE Alpha-i18n, please use any existing identifier */
        components: { default: Home }
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
]