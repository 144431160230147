import users from './users'
import languages from './languages'
import whitelabels from './whitelabels'
import directories from './directories'
import clients from './clients'
import contactPersons from './contactPersons'
import units from './units'

export default {
  users,
  languages,
  whitelabels,
  directories,
  clients,
  contactPersons,
  units
}