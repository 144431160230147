export default {
  setList (state, payload) {
    state.list = payload
  },

  // newItem (state, payload) {
  //   const validator = state.list.filter(item => item.id.toString() === payload.id.toString())

  //   if (validator.length === 0) {
  //     state.list.unshift({
  //       id: payload.id,
  //       at: payload.createdAt,
  //       content: payload.content,
  //       creator: payload.creator,
  //       isRead: payload.isRead
  //     })
  //   }
  // },

  // updateItem (state, payload) {
  //   let has = false
  //   for (let i = 0; i < state.list.length; i++) {
  //     if (state.list[i].id.toString() === payload.id.toString()) {
  //       has = true
  //       state.list[i].at = payload.createdAt
  //       state.list[i].content = payload.content
  //       state.list[i].creator = payload.creator
  //       state.list[i].isRead = payload.isRead
  //       break
  //     }
  //   }

  //   if (!has) {
  //     state.list.unshift({
  //       id: payload.id,
  //       at: payload.createdAt,
  //       content: payload.content,
  //       creator: payload.creator,
  //       isRead: payload.isRead
  //     })
  //   }
  // },

  deleteItem (state, id) {
    const list = state.list.filter(item => item.id.toString() !== id.toString())
    state.list = []
    state.list = [...list]
  },

  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}
