export default {
  defaultObject (_) {
    return {
      id: null,
      name: '',
      imei: '',
      wialonName: '',
      wialonImei: '',
      customFields: [],
      contactPersons: [],
      contactPersonsIds: []
    }
  }
}