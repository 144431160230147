export default {
  defaultObject (_) {
    return {
      id: null,
      name: '',
      type: null,
      updated: '',
      created: '',
      discharged: false
    }
  }
}