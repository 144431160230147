export default {
  avatar (state) {
    if (state.entity.avatar) {
      return state.entity.avatar
    }
    // return `${state.entity.name[0]}${state.entity.lastName[0]}`
    return `${state.entity.name[0]}`
  },

  hasAvatar (state) {
    return !(state.entity.avatar === undefined || state.entity.avatar === null)
  },

  fullName (state) {
    return `${state.entity.name} ${state.entity.lastName}`
  },

  token () {
    return 'rT2m3v2WCFAxM77qpqR4DjoXZHJeyYTdjXnxodkmBFOryfW8N3duSj3aDS10R6xV8idHGhhg'
  },

  defaultObject () {
    return {
      id: null,
      name: '',
      email: '',
      username: '',
      token: null
    }
  }
}